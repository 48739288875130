import { HashRouter, Link, NavLink, useNavigate } from 'react-router-dom';
import Logo from '../../assets/CFC-UNAP-vertical.png';
//import { useAuth} from '../../context/AuthProvider';
import AuthService from "../../api/ApiRest";
import { useContext } from 'react';
import { AuthContext } from '../../auth/authContext';
import { types } from '../../types/types';
import NavDropdown from 'react-bootstrap/NavDropdown';


export const Navbar = () => {
    const navigate = useNavigate();
    //const { auth, setAuth } = useAuth();
    const {user, dispatch}=useContext(AuthContext);

    const handleLogout =  () => {
        try {
            
            AuthService.logout();
            dispatch({type: types.logout});
            //setAuth({});
            navigate('/login',{replace:true});
        } catch (error) {
            console.log(error);
        }
    }
    //if (loading) return <h1>Loading</h1>
    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark">

            <Link
                className="navbar-brand"
                to="/"
            >
                <img src={Logo} style={{width:100}} alt="CFCUNAP" />
            </Link>

            <div className="navbar-collapse">
                <div className="navbar-nav">
                    <NavDropdown title="Cursos Abiertos" id="basic-nav-dropdown">
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/malumno">Ingreso Alumno</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/asistencia">Ingreso Asistencia Curso</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/notas">Ingreso Notas Curso</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/finaliza">Finalizar Curso</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/cursosalumnos">Alumnos y sus Cursos</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Cursos Cerrados" id="basic-nav-dropdown">
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mactividad">Nueva Actividad de Capacitación</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/actividades">Actividades en Desarrollo</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/actividadesfin">Actividades Finalizadas</NavDropdown.Item>
                    </NavDropdown>

                    <NavLink
                        className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')}
                        to="/foliador"
                    >
                        Foliador
                    </NavLink>

                    <NavDropdown title="SGC" id="basic-nav-dropdown">
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mproceso">Mantenedor de Procesos</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/procesos">Ver Procesos</NavDropdown.Item>
                    </NavDropdown>
                    
                    <NavDropdown title="Certificados" id="basic-nav-dropdown">
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/certificados">Diploma Cursos Abiertos</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/certificadoscerrados">Diploma Cursos Cerrados</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/cnotas">Notas</NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown title="Mantenedores" id="basic-nav-dropdown">
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/musuario">Mantenedor de Usuarios</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mempresa">Mantenedor de Empresas</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/msence">Mantendor Sence</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mactividadtipo">Mantendor Tipo Actividad</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mimputable">Mantendor Centro de Costos</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/marea">Mantenedor de Areas Instructores</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/minstructor">Mantendor Instructores</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mcurso">Mantendor Cursos</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/masignatura">Mantendor Asignaturas</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mciclo">Mantendor Ciclos</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mfirma">Mantendor Firmas</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mhorario">Mantendor Horarios</NavDropdown.Item>
                        <NavDropdown.Item className={({ isActive }) => "nav-item nav-link " + (isActive ? 'active' : '')} as={NavLink} to="/mperfil">Mantendor Permisos</NavDropdown.Item>
                    </NavDropdown>
                </div>
            </div>

            <div className="navbar-collapse collapse w-100 order-3 dual-collapse2 d-flex justify-content-end">
                <ul className="navbar-nav ml-auto">
                    <span className='nav-item nav-link text-info'> {user.nombres} {user.primer_apellido} {user.segundo_apellido}</span>
                    <button
                        className="nav-item nav-link btn"
                        onClick={handleLogout}
                    >
                        Cerrar Sesión
                    </button>
                </ul>
            </div>
        </nav>
    )
}